<template lang="pug">
.q-mb-md(style="position: relative")
  q-input(
    outlined,
    label-slot,
    style="position: relative",
    v-bind="$attrs",
    v-on="$listeners",
    :input-style="minHeight",
    ref="theControl"
  )
    //- template(v-slot:label)
  .floatinglabel
    .row
      .col-auto
        .bg-lilac.q-pa-xs.text-dark {{ name }}
</template>

<script>
export default {
  props: ["name"],
  computed: {
    minHeight() {
      return this.$attrs.type === "textarea" ? "min-height:100px;" : "";
    },
  },
  methods: {
    focus() {
      this.$refs.theControl.focus();
    },
    async validate() {
      return await this.$refs.formInput.validate();
    },
  },
};
</script>

<style lang="scss">
.bg-lilac {
  background-color: #d9bad7;
}

.text-purple {
  color: #4a2559;
}
</style>

<style lang="scss"></style>
